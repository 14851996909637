import { notification } from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';

import { store } from 'redux/store';
import actions from 'redux/user/actionTypes';
import client from './client';

export const login = async (payload) => {
  const { formData } = payload;

  try {
    const { data: { token, name } } = await client.post('/api/login/', formData);
    Cookies.set('token', token);
    Cookies.set('name', name);
    Cookies.set('username', formData.username);
    store.dispatch({
      type: actions.SET_STATE,
      payload: {
        name,
        token,
        username: formData.username,
      },
    });
    /* //////////////////////////////////////////////////////// */

    const permissions = {}

    localStorage.setItem('permissions', JSON.stringify(permissions));

    store.dispatch({
      type: actions.SET_STATE,
      payload: {
        permissions
      },
    })
    store.dispatch({ type: actions.SAVE_DRIVER_CITY, payload: 1 });
    store.dispatch({ type: actions.DRIVER_DEFAULT_CITY, payload: 1 });

    /* //////////////////////////////////////////////////////// */

    return true;
  } catch (error) {
    if (error?.message) {
      // notification.error({
      //   description: error?.message,
      // });
    }
    return false;
  }
};

function removeData() {
  Cookies.remove('token');
  Cookies.remove('name');
  Cookies.remove('team');
  Cookies.remove('teamName');
  Cookies.remove('manager');
  Cookies.remove('managerName');
  Cookies.remove('city');
  Cookies.remove('cityName');
  Cookies.remove('loc');
  Cookies.remove('location');
  Cookies.remove('partner');
  Cookies.remove('revenue');
  Cookies.remove('businessVerticalId');
  Cookies.remove('paymentModelId');
  Cookies.remove('teamInfo');
  Cookies.remove('username');
  localStorage.removeItem('permissions');
}

// load current account if token is verified and dispatch data
export const currentAccountLoad = async () => {
  try {
    const {
      userReducer: {
        token, permissions, dashboard, username,
      },
    } = store.getState();

    if (!token) return false;

    // To check whether selectedDash is even present in permissions
    const CONDITION = Object.keys(permissions).includes(dashboard);

    let selectedDash;
    if (CONDITION) {
      selectedDash = dashboard;
    } else {
      // eslint-disable-next-line prefer-destructuring
      selectedDash = Object.keys(permissions)[0];
      store.dispatch({
        type: actions.SET_STATE,
        payload: {
          dashboard: Object.keys(permissions)[0],
        },
      });
      Cookies.set('dashboard', Object.keys(permissions)?.[0]);
    }

    return true;
  } catch (error) {
    console.log('Error', error);
    if (error.response?.data?.detail === 'Invalid token.') {
      notification.error({
        description: 'Session expired: login again',
      });
      removeData();
      return false;
    }
    notification.error({
      description: error?.message,
    });
    return true;
  }
};

// logout and remove all the cookies
export const logout = async (action) => {
  try {
    const token = Cookies.get('token');

    const response = await axios({
      url: `${process.env.REACT_APP_CRM_API_URL}/api/logout/`,
      method: 'POST',
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (action === 'signout') {
      notification.success({
        message: response?.data?.message,
      });

      // Removing default city key here..
      localStorage.removeItem('userDefaultCity')
    }
    return true;
  } catch (error) {
    console.log(error);
    if (action === 'signout') {
      notification.success({
        message: 'Successfully logged out.',
      });
    }
    return true;
  } finally {
    removeData();
  }
};
