/* eslint-disable */
import React from 'react';
import { Tabs, Button, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { maskPhoneNumber } from 'components/common/PhoneNumberMask';
import { store } from 'redux/store';

const { TabPane } = Tabs;

const LeadHistoryTabs = (props) => {
  const { userReducer } = store.getState();
  const userPermissions = userReducer?.userDetails?.permissions?.map(permission => permission.codename);

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Details" key="1">
        <CustomerDetails
          customer={props?.leadHistories?.customer}
          handleCustomerEdit={props?.handleCustomerEdit}
        />
      </TabPane>
      {userPermissions?.includes('view_schedule') && (
        <TabPane tab="Schedule" key="2">
          <ScheduleDetails
            schedule={props?.ScheduleFormData}
            handleCustomerEdit={props?.handleCustomerEdit}
          />
        </TabPane>
      )}
    </Tabs>
  );
};

const CustomerDetails = ({ customer, handleCustomerEdit }) => (
  <fieldset style={{ padding: "10px", backgroundColor: "#006868" }} id='customerDetails'>
    <div className='row'>
      <div className='col-md-8'>
        <div>
          <b>Name: </b>
          <span>{`${customer?.first_name} ${customer?.last_name}`}</span>
        </div>
        <div>
          <b>Phone: </b>
          <span>{maskPhoneNumber(customer?.phone)}</span>
        </div>
        <div>
          <b>City: </b>
          <span>{customer?.city?.name}</span>
        </div>
        <div>
          <b>Source: </b>
          <span>{customer?.lead_source?.label}</span>
        </div>
        <div>
          <b>Sub Source: </b>
          <span>{customer?.sub_source?.label}</span>
        </div>
      </div>
      <div className='col-md-4'>
        <Button title='Edit' type="default" shape="round" icon={<EditOutlined />} onClick={() => handleCustomerEdit(customer, 'customer')} style={{ left: '50%' }}></Button>
      </div>
    </div>
  </fieldset>
);

const ScheduleDetails = ({ schedule, handleCustomerEdit }) => (
  <fieldset style={{ padding: "10px", backgroundColor: "#006868" }} id='scheduleDetails'>
    <div className='row'>
      <div className='col-md-8'>
        <div>
          <b>Date: </b>
          <span>{schedule?.date}</span>
        </div>
        <div>
          <b>Time Slots: </b>
          <span>{schedule?.timeslot}</span>
        </div>
        <div>
          <b>Location / Hub: </b>
          <span>{schedule?.location_hub?.location}</span>
        </div>
        <div>
          <b>Vehical Type: </b>
          <span>{schedule?.vehicle_type}</span>
        </div>
        <div>
          <b>Bussiness Modal: </b>
          <span>{schedule?.businessmodal?.name}</span>
        </div>
        {schedule?.remarks &&
          <div>
            <b>Remarks: </b>
            <span>{schedule?.remarks}</span>
          </div>
        }
      </div>
      {/* {userPermissions?.includes('change_schedule') && ( */}
      <div className='col-md-4'>
        <Button title='Edit' type="default" shape="round" icon={<EditOutlined />} onClick={() => handleCustomerEdit(schedule, 'schedule')} style={{ left: '50%' }}></Button>
      </div>
      {/* )} */}
    </div>
  </fieldset>
);

export default LeadHistoryTabs;
