/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { store } from 'redux/store';
import { useLocation } from 'react-router-dom';

const PermissionButton = ({ permissions, children, ...restProps }) => {
  const { userReducer } = store.getState();
  const { pathname } = useLocation()
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    const handleUserDetailsLoaded = () => {
      const { userReducer } = store.getState();
      const userPermissions = userReducer?.userDetails?.permissions?.map(permission => permission.codename);
      setIsAllowed(permissions?.every(permission => userPermissions?.includes(permission)));
    };
    console.log('pathname-', pathname)
    // Add event listener
    window.addEventListener('userDetailsLoaded', handleUserDetailsLoaded);

    // Initial check when component mounts
    handleUserDetailsLoaded();

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('userDetailsLoaded', handleUserDetailsLoaded);
    };
  }, [pathname]);

  return isAllowed ? <Button {...restProps}>{children}</Button> : null;
};

export default PermissionButton;
