import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';

import Router from 'router';
import { history } from 'redux/store';

import 'global.scss';
import 'components/kit/vendors/antd/mixins.less';

import CalenderProvider from 'context/calenderContext';
import DriverIDProvider from 'context/driverIDContext';
import TeamProvider from 'context/teamIDContext';
import DayCalenderProvider from 'context/dayCalenderContext';
import WeekOrDaySelectProvider from 'context/weekOrDaySelectContext';
import MenuProvider from 'context/menuContext';
import GuideProvider from 'context/guideContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';

// Set console log to empty function to avoid consoling in other environments except development.
if (process.env.REACT_APP_NODE_ENV !== 'development') console.log = function () { };

const App = () => {
  // Temporary fix in dev environment for antd table ResizeObserver error
  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === 'development') {
      window.addEventListener('error', (e) => {
        if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
          const resizeObserverErrDiv = document.getElementById(
            'webpack-dev-server-client-overlay-div',
          );
          const resizeObserverErr = document.getElementById(
            'webpack-dev-server-client-overlay',
          );
          if (resizeObserverErr) {
            resizeObserverErr.setAttribute('style', 'display: none');
          }
          if (resizeObserverErrDiv) {
            resizeObserverErrDiv.setAttribute('style', 'display: none');
          }
        }
      });
    }
  }, []);

  return (
    <GuideProvider>
      <CalenderProvider>
        <DriverIDProvider>
          <TeamProvider>
            <MenuProvider>
              <DayCalenderProvider>
                <WeekOrDaySelectProvider>
                  <Router history={history} />
                </WeekOrDaySelectProvider>
              </DayCalenderProvider>
            </MenuProvider>
          </TeamProvider>
        </DriverIDProvider>
      </CalenderProvider>
    </GuideProvider>
  );
};

export default Sentry.withProfiler(App);
