/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-unused-vars */
import { Layout, Spin } from 'antd';
import React, { useState, useEffect } from 'react';

import { useMenuContext } from 'context/menuContext';
import { useTeamContext } from 'context/teamIDContext';

import AppHeader from 'components/layouts/AppHeader';
import DashboardHeader from 'components/layouts/dashboardHeader';
import Sidebar from 'components/layouts/sidebar';
import Ameyo from 'components/common/Ameyo';
import { axiosInstance } from 'services/axios';
import { useSelector } from 'react-redux';

const { Header, Sider, Content, Footer } = Layout;

const MainLayout = ({
  children,
}) => {
  const {dashboard} = useSelector((state) => state.userReducer)

  const { menu, path } = useMenuContext();
  const { setSelectedDashboard } = useTeamContext();
  const [isAmeyoOpen, setIsAmeyoOpen] = useState(false);

  const [collapsed, setCollapsed] = useState(false);
  const [collapsedWidth, setCollapsedWidth] = useState(80);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setSelectedDashboard(dashboard);
  }, [dashboard]);

  const hideLayout = [
    '/home/driverHisaabData',
    '/home/deadKMsReportData',
    '/home/driverLeasHisaab/:id',
  ].includes(path);

  const onBreakpoint = (broken) => {
    if (broken) {
      setCollapsedWidth(0);
    } else {
      setCollapsedWidth(80);
    }
  };

  const getStyle = () => {
    if (menu === 'Dashboard') {
      return {
        padding: 0,
        height: 'auto',
      };
    }
    return {
      padding: 0,
      height: '80px',
    };
  };

  if (loading) {
    return (
      <div style={{width:"100%", height: "70vh"}}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <Spin />
        </div>
      </div>
    )
  }

  return (
    <>
      <Layout style={{ fontFamily: 'montserrat', minHeight: '94vh' }}>
        {hideLayout ? null
          : (
            <Sider
              collapsible
              collapsed={collapsed}
              onCollapse={() => setCollapsed((prevState) => !prevState)}
              width={250}
              style={{
                // height: '94vh',
                position: 'sticky',
                overflow: 'auto',
                left: 0,
                top: 0,
                bottom: 0,
                // position: 'absolute'
              }}
              className="shadow-sm"
              breakpoint="lg"
              onBreakpoint={(broken) => onBreakpoint(broken)}
              collapsedWidth={collapsedWidth}
            >
              <Sidebar />
            </Sider>
          )}
        <Layout>
          {hideLayout ? null
            : (
              <Header style={getStyle()}>
                {menu === 'Dashboard' ? <DashboardHeader /> : <AppHeader />}
              </Header>
            )}
          <Content>
            {children}
          </Content>

        </Layout>
      </Layout>

      <Footer>
        <Ameyo isAmeyoOpen={isAmeyoOpen} setIsAmeyoOpen={setIsAmeyoOpen} />
      </Footer>
    </>
  );
};
export default MainLayout;
