import actions from './actionTypes';

const userLogin = (payload) => ({
  type: actions.LOGIN,
  payload,
});

const userLoad = (payload) => ({
  type: actions.LOAD_CURRENT_ACCOUNT,
  payload,
});

const userLoginError = (payload) => ({
  type: actions.SET_STATE,
  payload,
});

const userLogout = (payload) => ({
  type: actions.LOGOUT,
  payload,
});

const setSelectedCity = (cityId) => ({
  type: actions.SET_SELECTED_CITY,
  payload: cityId,
});

const setUserDetails = (data) => ({
  type: actions.SET_USER_DETAILS,
  payload: data,
});

const setUserRoles = (data) => ({
  type: actions.SET_USER_ROLES,
  payload: data,
});

export {
  userLogin,
  userLoginError,
  userLogout,
  userLoad,
  setSelectedCity,
  setUserDetails,
  setUserRoles,
};
