/* eslint-disable max-len */
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { store } from 'redux/store';

import App from 'app';

// if (process.env.REACT_APP_NODE_ENV === 'production' || process.env.REACT_APP_NODE_ENV === 'staging') {
//   Sentry.init({
//     dsn: 'https://e498660aed4d0ac6ed2e304c962de21d@o4504513965326336.ingest.sentry.io/4506788907188224',
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       Sentry.replayIntegration({
//         maskAllText: false,
//         blockAllMedia: false,
//       }),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions

//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

//     // // Session Replay
//     // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.

//     // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// }

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
