/* eslint-disable */
import React, { useState } from 'react';
import { Button, Popover, Select } from 'antd';
import { connect } from 'react-redux';
import { axiosInstance } from 'services/axios';
import { userLogout, userLoad, setSelectedCity, setUserDetails } from 'redux/user/actions';
import { useTeamContext } from 'context/teamIDContext';
import UpdatePassModal from 'lib/updatePassModal';
import logo from '../../../assets/images/everestFleetLogo.png';
import { store } from 'redux/store';
class CityComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      cities: [],
      localSelectedCity: null,
    };
  }

  componentDidMount() {
    
    this.fetchCities();
  }

  fetchCities = async () => {
    this.setState({ loading: true });
    try {
      const response = await axiosInstance.get('/users/details/');
      const cities = response.data?.cities || [];

      this.setState({
        loading: false,
        cities
      });
      this.props.setUserDetails(response.data);

      // After fetching user details and updating the store
      const event = new CustomEvent('userDetailsLoaded');
      window.dispatchEvent(event);

      if (cities.length > 0) {
        this.setState({
          localSelectedCity: cities[0].id,
        });
        localStorage.setItem('userDefaultCity', cities[0].id);
        localStorage.setItem('cities', JSON.stringify(cities));
        this.props.setSelectedCity(cities[0].id);
      }
    } catch (error) {
      this.setState({
        loading: false,
        error: "Couldn't load cities!",
      });
    }
  };


  handleCityChange = (value) => {
    this.setState({
      localSelectedCity: value,
    });
    localStorage.setItem('userDefaultCity', value);
    this.props.setSelectedCity(value);
  };

  render() {
    const { cities, localSelectedCity } = this.state;

    return (
      <Select
        value={localSelectedCity}
        onChange={this.handleCityChange}
        options={cities.map((city) => ({
          value: city.id,
          label: city.name,
        }))}
        style={{
          width: '11rem',
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCity: state.userReducer.selectedCity,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedCity: (city) => dispatch(setSelectedCity(city)),
  setUserDetails: (userData) => dispatch(setUserDetails(userData)),
  setUserRoles: (userRole) => dispatch(setUserRoles(userRole)),
  logout: (action) => dispatch(userLogout(action)),
  loadCurrentAccount: () => dispatch(userLoad()),
});

const ConnectedCityComponent = connect(mapStateToProps, mapDispatchToProps)(CityComponent);

const Header = ({ logout, loadCurrentAccount, userDetails }) => {
  const { userReducer } = store.getState();
  const { teamInfo } = userReducer;

  const { selectedDashboard } = useTeamContext();

  const [isPassModalOpen, setPassModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSignOut = () => {
    setLoading(true);
    logout('signout');
  };

  return (
    <div className="p-4 pb-2 flex flex-col">
      {isPassModalOpen && (
        <UpdatePassModal
          logout={logout}
          isModalOpen={isPassModalOpen}
          setModalOpen={setPassModalOpen}
        />
      )}
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center gap-4">
          {selectedDashboard !== 'Jarvis' && (
            <img
              className="rounded-full"
              width="0"
              height="0"
              src={teamInfo?.Logo ?? logo}
              alt={teamInfo?.id}
            />
          )}
          <p className="text-[#333333] uppercase font-extrabold text-sm m-0">
            {selectedDashboard !== 'Jarvis' && teamInfo?.name}
          </p>
        </div>

        <div className="flex gap-4 md:flex-row flex-col-reverse items-center">
          <ConnectedCityComponent />

          <Popover
            placement="bottomRight"
            title={userDetails?.first_name}
            content={(
              <div className="flex flex-col gap-2">
                <Button
                  onClick={() => setPassModalOpen(true)}
                  type="button"
                  className="text-xs hover:text-blue-400"
                >
                  Change password
                </Button>
                <Button
                  onClick={handleSignOut}
                  type="primary"
                  loading={loading}
                >
                  Sign Out
                </Button>
              </div>
            )}
            trigger="click"
          >
            <img
              src="/assets/images/general/man.svg"
              alt="user"
              width="40"
              className="cursor-pointer"
            />
          </Popover>
        </div>
      </div>
    </div>
  );
};

const mapHeaderStateToProps = (state) => ({
  selectedCity: state.userReducer.selectedCity,
  userDetails: state.userReducer.userDetails,
});


const ConnectedHeader = connect(mapHeaderStateToProps, mapDispatchToProps)(Header);

export default ConnectedHeader;
