import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';

// layout imports
import PublicLayout from './public';
import AuthLayout from './auth';
import MainLayout from './main';

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
};

const SelectedLayout = ({
  isLoading,
  isUserAuthorized,
  isAuthLayout,
  Container,
  pathname,
  children,
}) => {
  // show loader when user in check authorization process
  if (isLoading && !isUserAuthorized && !isAuthLayout) {
    return null;
  }

  // redirect to login page if current is not login page and user not authorized
  if (!isAuthLayout && !isUserAuthorized) {
    return <Redirect to="/auth/sign-in" />;
  }

  // in other case render previously set layout
  return <Container path={pathname}>{children}</Container>;
};

const Layout = ({
  isLoading,
  isUserAuthorized,
  children,
}) => {
  const { pathname } = useLocation();
  const getLayout = () => {
    if (pathname === '/') return 'public';
    if (/^\/auth(?=\/|$)/i.test(pathname)) return 'auth';
    return 'main';
  };

  useEffect(() => {
    moment.updateLocale('en', {
      week: {
        dow: 1, /// Date offset
      },
    });
  }, []);

  const Container = Layouts[getLayout()];

  const isAuthLayout = (getLayout() === 'auth');

  return (
    <>
      <Helmet titleTemplate="Everest Fleet | %s" />
      <SelectedLayout
        isLoading={isLoading}
        isUserAuthorized={isUserAuthorized}
        isAuthLayout={isAuthLayout}
        Container={Container}
        pathname={pathname}
      >
        {children}
      </SelectedLayout>
    </>
  );
};

const mapStateToProps = ({ userReducer }) => ({
  isLoading: userReducer.loading,
  isUserAuthorized: userReducer.authorized,
});

export default connect(mapStateToProps, null)(Layout);
