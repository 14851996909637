/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useState, useContext } from 'react';

// initial default value
const MenuContext = createContext({
  menu: '',
  path: '/home/dashboard',
  pageObject: {
    label: 'Dashboard',
    key: '/home/dashboard',
    icon: '',
    uri: 'dashboard',
    calendar_type: '"THIS_WEEK"',
  },
});

const MenuProvider = ({ children }) => {
  const [menu, setMenu] = useState('');
  const [path, setPath] = useState('/home/dashboard');
  const [pageObject, setPageObject] = useState({
    label: 'Dashboard',
    key: '/home/dashboard',
    icon: '',
    uri: 'dashboard',
    calendar_type: '"THIS_WEEK"',
  });

  return (
    <MenuContext.Provider value={{
      menu, setMenu, path, setPath, pageObject, setPageObject,
    }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuContext = () => useContext(MenuContext);
export default MenuProvider;
